// translations/login.js

export default {
    ru: {
        title: "Вход",
        recovery: {
            title: "Восстановление пароля",
            text: "Укажите вашу почту, и мы отправим вам ссылку для восстановления пароля",
            email: "Электронная почта",
            button: "Отправить",
            back: "Назад"
        },
        form: {
            email: "Электронная почта",
            password: "Пароль",
            wrong: "Неверный email или пароль",
            forgot: "Забыл пароль",
            login: "Войти"
        },
        noAccount: "Нет аккаунта?",
        register: "Зарегистрироваться"
    },
    en: {
        title: "Sign in",
        recovery: {
            title: "Password recovery",
            text: "Enter your email address and we'll send you a password reset link",
            email: "Email address",
            button: "Send",
            back: "Back"
        },
        form: {
            email: "Email address",
            password: "Password",
            wrong: "Incorrect email or password",
            forgot: "Forgot password",
            login: "Sign in"
        },
        noAccount: "Don't have an account?",
        register: "Sign up"
    }
}