// plugins/i18n.js

import common from '@/translations/common'
import menu from '@/translations/menu'
import toolbars from '@/translations/toolbars'
import dialogs from '@/translations/dialogs'
import materials from '@/translations/materials'
import products from '@/translations/products'
import categories from '@/translations/categories'
import suppliers from '@/translations/suppliers'
import files from '@/translations/files'
import purchases from '@/translations/purchases'
import statuses from '@/translations/statuses'
import production from '@/translations/production'
import register from '@/translations/register'
import login from '@/translations/login'
import { pluralizeTime, pluralizeEntities } from '@/translations/plurals'
import dayjs from "dayjs"
import store from '@/store'
import * as Sentry from "@sentry/vue"

const translations = {
    common,
    menu,
    toolbars,
    dialogs,
    materials,
    products,
    categories,
    suppliers,
    files,
    purchases,
    statuses,
    production,
    register,
    login
}

// TODO: Uncomment the line below to enable locale switching
// const locale = process.env.VUE_APP_LOCALE || 'en'

const locale = 'en'
// const locale = 'ru'

export default {
    install(Vue) {
        Vue.prototype.$locale = locale

        Vue.prototype.t = function (key) {
            // t for translate

            // Проверяем, содержит ли ключ точку
            const hasModule = key.includes('.')

            // Если точки нет, добавляем 'common.' в начало
            const fullKey = hasModule ? key : `common.${key}`

            const [module, ...path] = fullKey.split('.')
            try {
                const translation = path.reduce((obj, key) => {
                    if (obj === undefined) throw new Error()
                    return obj[key]
                }, translations[module][locale])

                if (translation === undefined) {
                    throw new Error()
                }

                return translation
            } catch {
                Sentry.captureMessage(`Lost in translation: ${key}`)
                console.error(`Lost in translation: ${key}`)
                return key
            }
        }

        Vue.prototype.pluralize = function (number, entity) {
            if (entity == 'days' || entity == 'months') {
                return pluralizeTime(number, entity, locale)
            }
            else if (entity == 'materials' || entity == 'products'
                || entity == 'customers' || entity == 'suppliers'
            ) {
                return pluralizeEntities(number, entity, locale)
            }
        }

        Vue.prototype.formatDate = function (date) {
            if (!date) return ''

            const format = store.getters.user?.prefDateFormat || 'DD.MM.YYYY'

            return dayjs(date).format(format)
        }
    }
}