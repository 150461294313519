import dayjs from "dayjs"
import store from '@/store'

// Кэш для number форматтеров
const numberFormattersCache = new Map()

// Кэш для currency форматтера
let currencyFormatter = null
let lastLocale = null
let lastCurrencyCode = null
let lastDecimals = null

// Функция для получения ключа кэша
const getCacheKey = (locale, decimals) => `${locale}-${decimals}`

// Функция для получения форматтера чисел из кэша
const getNumberFormatter = (locale, decimals = null) => {
    const key = getCacheKey(locale, decimals)

    if (!numberFormattersCache.has(key)) {
        const options = decimals !== null ? {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        } : {}

        numberFormattersCache.set(key, new Intl.NumberFormat(locale, options))
    }

    return numberFormattersCache.get(key)
}

// Функция для получения форматтера валют
const getCurrencyFormatter = (locale, currencyCode, decimals) => {
    if (lastLocale !== locale
        || lastCurrencyCode !== currencyCode
        || lastDecimals !== decimals
    ) {
        currencyFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
        lastLocale = locale
        lastCurrencyCode = currencyCode
        lastDecimals = decimals
    }

    return currencyFormatter
}

export default {
    install(Vue) {
        Vue.prototype.formatNumber = function (value, decimals = null) {
            const formatter = getNumberFormatter(store.getters.locale, decimals)
            return formatter.format(value)
        }

        Vue.prototype.formatCurrency = function (value) {
            if (store.getters.user.currencyCode === '') {
                // In case of empty currency code, just format number
                return this.formatNumber(value, 2)
            }
            else {
                const formatter = getCurrencyFormatter(
                    store.getters.locale,
                    store.getters.user.currencyCode,
                    store.getters.user.prefDecimals ? 2 : 0
                )
                return formatter.format(value)
            }
        }

        Vue.prototype.formatPercent = function (value, decimals = 1) {
            const formatter = getNumberFormatter(store.getters.locale, decimals)
            return formatter.format(value) + '%'
        }

        Vue.prototype.formatDate = function (date) {
            if (!date) return ''

            const format = store.getters.user?.prefDateFormat || 'DD.MM.YYYY'
            return dayjs(date).format(format)
        }
    }
}