// translations/products.js

export default {
    ru: {
        cost: 'Себестоимость',
        cst: 'Себ-ть',
        price: 'Цена продажи',
        prc: 'Цена',
        components: 'Состав',
        productsLimitReached: 'Достигнут лимит продуктов',
        noDataCta: {
            title: 'Начните с добавления своего первого продукта',
            subtitle: 'Продукты – это то, что вы производите из ваших материалов',
        },
        create: {
            title: 'Создать',
            production: 'Создать производство',
            order: 'Создать заказ',
            transfer: 'Создать перемещение',
            audit: 'Создать инвентаризацию'
        },
        hide: {
            disabledTooltip: 'Нельзя скрыть продукты, у которых есть остаток на складе',
            message1: 'Продукты будут скрыты со склада',
            message2: 'Вы всегда сможете настроить, какие продукты должны отображаться на этом складе в настройках склада. Скрыть эти продукты?',
            multipleSnackbar: 'Продукты скрыты'
        },
        delete: {
            message: 'Вы уверены?',
            multipleMessage: 'Вы собираетесь безвозвратно удалить {count} продуктов. Восстановить их будет невозможно. Вы уверены, что хотите удалить эти продукты?',
            multipleSnackbar: 'Продукты удалены'
        },
        exportMessage: 'Экспорт списка продуктов',
        view: {
            menu: {
                exportHistory: 'Экспорт истории',
                exportComponents: 'Экспорт состава',
            },
            noMaterials: 'Материалы не указаны',
            noResources: 'Ресурсы не указаны',
            usedIn: 'Используется в составе',
            usedIn1Product: 'продукта',
            usedInNProducts: 'продуктов',
            correctionEdit: 'Редактирование корректировки остатка',
            creationEdit: 'Редактирование создания продукта',
            initialStock: 'Начальный остаток',
            print: {
                title: 'Печать продукта',
                superproducts: 'Печатать продукты, в состав которых входит данный',
                components: 'Печатать материалы',
                resources: 'Печатать ресурсы',
                history: 'Печатать историю',
                hidePrices: 'Скрыть стоимость'
            },
            exportHistoryMessage: 'Экспорт истории продукта',
            exportComponentsMessage: 'Экспорт состава продукта'
        },
        edit: {
            unitChangeWarning: 'Другие единицы измерения недоступны, так как этот продукт используется в составе других продуктов',
            addRow: 'Добавить строку',
            components: {
                add: 'Создать новый материал',
                duplicateWarning: 'Это повтор. Вы можете создать продукт с повторяющимися материалами, мы просто предупреждаем.',
                emptyMessage: 'Добавьте материалы, входящие в состав продукта, чтобы отслеживать их остатки по мере производства и учитывать их в себестоимости продукта.',
                createFirst: 'Сначала создайте хотя бы один материал',
                selectFirst: 'Сначала выберите материал'
            },
            resources: {
                add: 'Создать новый ресурс',
                duplicateWarning: 'Это повтор. Вы можете создать продукт с повторяющимися ресурсами, мы просто предупреждаем.',
                emptyMessage: 'Вы можете добавить ресурсы, чтобы учитывать в себестоимости зарплату сотрудников, коммунальные расходы и т.д.',
                createFirst: 'Сначала создайте хотя бы один ресурс',
                selectFirst: 'Сначала выберите ресурс'
            },
            multiple: {
                differentValues: 'Разные значения',
                differentUnits: 'Разные единицы измерения',
            },
        },
        stockUpdate: {
            title: 'Обновить остаток',
            type: 'Тип',
        },
        snackbar: {
            added: "Продукт добавлен",
            edited: "Продукт обновлен",
            deleted: "Продукт удален",
            hidden: "Продукт скрыт",
            editedMultiple: "Продукты обновлены",
            deletedMultiple: "Продукты удалены",
            hiddenMultiple: "Продукты скрыты",
        }
    },
    en: {
        cost: 'Cost',
        cst: 'Cost',
        price: 'Sales price',
        prc: 'Price',
        components: 'Components',
        productsLimitReached: 'Products limit reached',
        noDataCta: {
            title: 'Start by adding your first product',
            subtitle: 'Products are what you make from your materials',
        },
        create: {
            title: 'Create',
            production: 'Create production',
            order: 'Create order',
            transfer: 'Create transfer',
            audit: 'Create audit'
        },
        hide: {
            disabledTooltip: 'Cannot hide products with stock',
            message1: 'Products will be hidden from storage',
            message2: 'You can always configure which products should be displayed in this storage in storage settings. Hide these products?',
            multipleSnackbar: 'Products hidden'
        },
        delete: {
            message: 'Are you sure?',
            multipleMessage: 'You are about to permanently delete {count} products. You will not be able to recover them. Are you sure you want to delete these products?',
            multipleSnackbar: 'Products deleted'
        },
        exportMessage: 'Export products list',
        view: {
            menu: {
                exportHistory: 'Export history',
                exportComponents: 'Export components',
            },
            noMaterials: 'Materials not specified',
            noResources: 'Resources not specified',
            usedIn: 'Used in',
            usedInProduct: 'product',
            usedInProducts: 'products',
            correctionEdit: 'Edit stock correction',
            creationEdit: 'Edit product creation',
            initialStock: 'Initial stock',
            print: {
                title: 'Print product',
                superproducts: 'Print products that use this product',
                components: 'Print materials',
                resources: 'Print resources',
                history: 'Print history',
                hidePrices: 'Hide prices'
            },
            exportHistoryMessage: 'Export product\'s history',
            exportComponentsMessage: 'Export product\'s components'
        },
        edit: {
            unitChangeWarning: 'Other units are not available because this product is used in other products',
            addRow: 'Add row',
            components: {
                add: 'Create new material',
                duplicateWarning: 'This is a duplicate. You can create a product with duplicate materials, we are just warning you.',
                emptyMessage: 'Add materials used in the product to track their inventory and include them in the product cost calculation.',
                createFirst: 'Create at least one material first',
                selectFirst: 'Select a material first'
            },
            resources: {
                add: 'Create new resource',
                duplicateWarning: 'This is a duplicate. You can create a product with duplicate resources, we are just warning you.',
                emptyMessage: 'You can add resources to account for employee wages, utilities, and other overhead costs.',
                createFirst: 'Create at least one resource first',
                selectFirst: 'Select a resource first'
            },
            multiple: {
                differentValues: 'Different values',
                differentUnits: 'Different units of measure',
            }
        },
        stockUpdate: {
            title: 'Update stock',
            type: 'Type',
        },
        snackbar: {
            added: "Product added",
            edited: "Product updated",
            deleted: "Product deleted",
            hidden: "Product hidden",
            editedMultiple: "Products updated",
            deletedMultiple: "Products deleted",
            hiddenMultiple: "Products hidden",
        }
    }
}
