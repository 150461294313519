import api from '@/lib/api'

export default {
    namespaced: true,
    state: {
        units: [],
        unitsWithHeaders: [],
        loading: false
    },
    getters: {
        materialUnits: state => state.units.filter(u => u.material),
        materialUnitsWithHeaders: state => state.unitsWithHeaders.filter(u => u.material),
        resourceUnits: state => state.units,
        resourceUnitsWithHeaders: state => state.unitsWithHeaders,
        loading: state => state.loading,
        loaded: state => state.units.length > 0,
    },
    mutations: {
        setUnits(state, units) {
            state.units = units
        },
        setUnitsWithHeaders(state, units) {
            state.unitsWithHeaders = units
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
    },
    actions: {
        getUnits(context, force = false) {
            // Единицы измерения загружаются только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loaded && !force) return

            context.commit('startLoading')
            api.post("units/get_list").then((response) => {
                context.commit('setUnits', response.data.units)
                context.commit('setUnitsWithHeaders', response.data.unitsWithHeaders)
                context.commit('stopLoading')
            })
        }
    }
}