// translations/common.js

// В этом словаре специально нет следующих ключей,
// Поскольку их перевод зависит от контекста
// И должен быть определен в конкретных модулях словаря:
// - price
// - cost
// - components

export default {
    ru: {
        aCopy: "Копия",
        add: "Добавить",
        amount: "Количество",
        amt: "Кол-во",
        and: "и",
        areYouSure: "Вы уверены?",
        audit: "Инвентаризация",
        audits: "Инвентаризации",
        back: "Назад",
        belowMinimum: "Ниже минимума",
        cancel: "Отмена",
        categories: "Категории",
        categoriesAny: "Любая из выбранных категорий",
        category: "Категория",
        comment: "Комментарий",
        continue: "Продолжить",
        copy: "Копировать",
        correction: "Корректировка",
        close: "Закрыть",
        create: "Создать",
        customer: "Покупатель",
        customers: "Покупатели",
        date: "Дата",
        dated: "от",
        datePlaced: "Дата заказа",
        dateReceived: "Дата получения",
        dateShipped: "Дата отправки",
        delete: "Удалить",
        delivery: "Доставка",
        discount: "Скидка",
        edit: "Редактировать",
        error: "Ошибка",
        export: "Экспорт",
        files: "Файлы",
        filters: "Фильтры",
        from: "От",
        fromTime: "С",
        hide: "Скрыть",
        history: "История",
        import: "Импорт",
        inStorage: "На складе",
        itemsPerPage: "Записей на странице",
        itemsPerPageAll: "Все",
        loading: "Загрузка...",
        material: "Материал",
        materials: "Материалы",
        materialsStorage: "Склад материалов",
        merge: "Объединить",
        minimum: "Минимум",
        minimumStock: "Минимальный остаток",
        n: "№",
        name: "Название",
        noData: "Ничего не найдено",
        noHistory: "Истории пока нет",
        noName: "Без названия",
        notes: "Заметки",
        ok: "Ок",
        or: "или",
        order: "Заказ",
        orders: "Заказы",
        payment: "Оплата",
        per: "за",
        pleaseWaitForLoading: "Подождите, идет загрузка данных",
        print: "Печать",
        product: "Продукт",
        production: "Производство",
        productions: "Производство",
        products: "Продукты",
        productsStorage: "Склад продуктов",
        purchase: "Поставка",
        purchases: "Поставки",
        report: "Отчет",
        reports: "Отчеты",
        resource: "Ресурс",
        resources: "Ресурсы",
        save: "Сохранить",
        search: "Поиск",
        sku: "SKU",
        status: "Статус",
        stock: "Остаток",
        stockBelowMinimum: "Остаток ниже минимума",
        stockUpdated: "Остаток обновлен",
        storage: "Склад",
        storages: "Склады",
        subtotal: "Подытог",
        supplier: "Поставщик",
        suppliers: "Поставщики",
        suppliersAny: "Любой из выбранных поставщиков",
        to: "до",
        toTime: "По",
        total: "Итого",
        transfer: "Перемещение",
        transfers: "Перемещения",
        unit: "Единица измерения",
        unt: "Ед. изм.",
        upgradePlan: "Повысить тариф",
        writeoff: "Списание",
        writeoffs: "Списания",
    },
    en: {
        aCopy: "Copy",
        add: "Add",
        amount: "Quantity",
        amt: "Qty",
        and: "and",
        areYouSure: "Are you sure?",
        audit: "Audit",
        audits: "Audits",
        back: "Back",
        belowMinimum: "Below minimum",
        cancel: "Cancel",
        categories: "Categories",
        categoriesAny: "Any of selected categories",
        category: "Category",
        close: "Close",
        comment: "Comment",
        continue: "Continue",
        copy: "Copy",
        correction: "Correction",
        create: "Create",
        customer: "Customer",
        customers: "Customers",
        date: "Date",
        dated: "of",
        datePlaced: "Date placed",
        dateReceived: "Date received",
        dateShipped: "Date shipped",
        delete: "Delete",
        delivery: "Delivery",
        discount: "Discount",
        edit: "Edit",
        error: "Error",
        export: "Export",
        files: "Files",
        filters: "Filters",
        from: "From",
        fromTime: "From",
        hide: "Hide",
        history: "History",
        import: "Import",
        inStorage: "In location",
        itemsPerPage: "Items per page",
        itemsPerPageAll: "All",
        loading: "Loading...",
        material: "Material",
        materials: "Materials",
        materialsStorage: "Materials location",
        merge: "Merge",
        minimum: "Minimum",
        minimumStock: "Minimum stock",
        n: "#",
        name: "Name",
        noData: "No data found",
        noHistory: "No history yet",
        noName: "No name",
        notes: "Notes",
        ok: "Ok",
        or: "or",
        order: "Sale",
        orders: "Sales",
        payment: "Payment",
        per: "per",
        pleaseWaitForLoading: "Please wait for loading",
        print: "Print",
        product: "Product",
        production: "Production",
        productions: "Productions",
        products: "Products",
        productsStorage: "Products location",
        purchase: "Purchase",
        purchases: "Purchases",
        report: "Report",
        reports: "Reports",
        resource: "Resource",
        resources: "Resources",
        save: "Save",
        search: "Search",
        sku: "SKU",
        status: "Status",
        stock: "Stock level",
        stockBelowMinimum: "Stock below minimum",
        stockUpdated: "Stock updated",
        storage: "Location",
        storages: "Storage locations",
        subtotal: "Subtotal",
        supplier: "Supplier",
        suppliers: "Suppliers",
        suppliersAny: "Any of selected suppliers",
        to: "To",
        total: "Total",
        toTime: "To",
        transfer: "Transfer",
        transfers: "Transfers",
        unit: "Unit of measure",
        unt: "Unit",
        upgradePlan: "Upgrade plan",
        writeoff: "Writeoff",
        writeoffs: "Writeoffs",
    }
}