import api from '@/lib/api'

export default {
    namespaced: true,
    state: {
        list: [],
        names: [],
        loading: false,
        emptyItem: {
            id: 0,
            name: "",
            stock: "",
            minimum: "",
            unitId: 0,
            price: "",
            categories: [],
            suppliers: [],
            notes: "",
            files: [],
            newFiles: [],
            loaded: false
        }
    },
    getters: {
        list: state => state.list,
        names: state => state.names,
        prefixedNames: state => state.names.map((item) => ({
            id: 'm-' + item.id,
            name: item.name,
            unit: item.unit,
            unitId: item.unitId
        })),
        loading: state => state.loading,
        emptyItem: state => state.emptyItem,
    },
    mutations: {
        setList(state, list) {
            state.list = list
        },
        clear(state) {
            state.list = []
            state.names = []
        },
        setNames(state, names) {
            state.names = names
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
    },
    actions: {
        clear(context) {
            context.commit('clear')
        },
        getNames(context) {
            context.commit('startLoading')
            return api.post("materials/get_names").then((response) => {
                context.commit('setNames', response.data.items)
                context.commit('stopLoading')
            })
        },
        getList(context) {
            context.commit('startLoading')
            return api.post("materials/get_list", {
                // storageId может быть 0, если еще не получен ответ storages/get_list
                // в таком случае на сервере будет выбран тот склад,
                // который сохранен в БД для пользователя как выбранный 
                storageId: context.rootGetters['storages/materialsId']
            }).then((response) => {
                context.commit('setList', response.data.items)
                context.commit('setNames', response.data.items.map((item) => ({
                    id: item.id,
                    name: item.name,
                    unit: item.unit,
                    unitId: item.unitId
                })))
                context.commit('stopLoading')
            })
        },
    }
}