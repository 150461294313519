// translations/dialogs.js

export default {
    ru: {
        superConfirm: {
            typeToConfirm: 'Для подтверждения введите текст',
            below: 'в поле ниже',
            error: 'Текст не совпадает',
        },
        connection: {
            title: 'Проблемы с сетью',
            text: 'Похоже, что-то случилось с соединением с интернетом'
        },
        trialPastDue: {
            title: 'Выберите тарифный план',
            text1: 'Пробный период использования сервиса закончился. Пожалуйста, выберите тарифный план, чтобы и дальше пользоваться сервисом.',
            text2: 'Если вам нужно больше времени для принятия решения, пожалуйста, напишите нам в чат.'
        },
        pastDue: {
            title: 'Обновите платежные данные',
            card: 'Мы не смогли списать очередную оплату с вашей карты. Пожалуйста внесите оплату, чтобы и дальше пользоваться вашим тарифным планом.',
            invoice: 'Мы не получили оплату по выставленному счету. Пожалуйста внесите оплату, чтобы и дальше пользоваться вашим тарифным планом.<br /><br />Если вы уже оплатили счет, то пожалуйста, напишите нам в чат и пришлите копию платежного поручения.'
        },
        export: {
            title: 'Экспорт',
            format: 'Формат',
            download: 'Загрузить',
        },
        maintenance: {
            title: 'Технические работы 🛠',
            text: 'На сайте проводятся технические работы. Мы скоро все починим. Приносим извинения за неудобства.'
        }
    },
    en: {
        superConfirm: {
            typeToConfirm: 'Please type this text to confirm',
            below: 'in the box below',
            error: 'That doesn\'t match - please try again',
        },
        connection: {
            title: 'Having Trouble Connecting?',
            text: 'Looks like we\'re having issues connecting to the internet'
        },
        trialPastDue: {
            title: 'Time to Pick a Plan',
            text1: 'Your free trial has come to an end. Ready to pick a plan and keep the good things going?',
            text2: 'Need a bit more time to decide? Just drop us a message in chat.'
        },
        pastDue: {
            title: 'Quick Payment Update Needed',
            card: 'We weren\'t able to process your card payment. Would you mind updating your payment details so you can keep using all the features?',
            invoice: 'We haven\'t caught your payment for the latest invoice yet. A quick payment will keep everything running smoothly.<br /><br />Already paid? No worries - just shoot us a message in chat with your payment receipt.'
        },
        export: {
            title: 'Export',
            format: 'Choose Format',
            download: 'Get File',
        },
        maintenance: {
            title: 'Quick Tune-up in Progress 🛠',
            text: 'We\'re doing some behind-the-scenes maintenance to make things even better. Be back before you know it!'
        }
    }
}