export function numberFormat(number, decimals, dec_point, thousands_sep) {
	number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = typeof thousands_sep === "undefined" ? " " : thousands_sep,
		dec = typeof dec_point === "undefined" ? "." : dec_point,
		s = "",
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec)
			return "" + (Math.round(n * k) / k).toFixed(prec)
		}
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
	}
	if ((s[1] || "").length < prec) {
		s[1] = s[1] || ""
		s[1] += new Array(prec - s[1].length + 1).join("0")
	}
	return s.join(dec)
}
export function toNum(string) {
	let number = (string + "").replace(',', '.').replace(/\s/g, '')
	return +number
}
export function toStr(number, decimals) {
	decimals = decimals === undefined ? 2 : decimals
	return numberFormat(number, decimals)
}
export function toCurrency(number, user) {
	if (user.currencyLeft) {
		return `${user.currency} ${toStr(number, user.decimals)}`
	} else {
		return `${toStr(number, user.decimals)} ${user.currency}`
	}
}
export function orderNum(num) {
	// check if number is integer
	if (typeof num === 'number' && Number.isInteger(num) && num >= 0 && num <= 9999) {
		return num.toString().padStart(4, "0")
	}
	else {
		return num
	}
}