// translations/menu.js

export default {
    ru: {
        other: 'Другое',
        settings: 'Настройки',
        help: 'База знаний',
        admin: 'Панель администратора',
        loggedInAs: "Вы зашли как",
        exitViewAs: "Вернуться",
        pin: "Закрепить меню",
        unpin: "Открепить меню",
    },
    en: {
        other: 'Other',
        settings: 'Settings',
        help: 'Help Center',
        admin: 'Admin Panel',
        loggedInAs: "Logged in as",
        exitViewAs: "Exit",
        pin: "Pin menu",
        unpin: "Unpin menu",
    }
}