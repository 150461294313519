export default {
    ru: {
        type: 'Тип',
        personalName: 'Имя',
        legalName: 'Наименование',
        inn: 'ИНН',
        kpp: 'КПП',
        ogrn: 'ОГРН',
        address: 'Юридический адрес',
        manager: 'Руководитель',
        post: 'Должность',
        phone: 'Телефон',
        email: 'Email',
        agreement: 'Договор',
        purchasesCount: 'Количество поставок',
        purchasesTotal: 'Сумма поставок',
        deliveryDaysAvg: "Среднее время доставки",
        deliveryDays: "Дней до доставки",
        purchaseTotal: "Сумма",
        exportMessage: "Экспорт списка поставщиков",
        mergeMessage: "Никакая уникальная информация не будет потеряна. Вы уверены?",
        noDataCta: {
            title: 'Здесь будут показаны ваши поставщики',
            subtitle: 'Вы можете добавить поставщика в процессе создания поставки или на этом экране'
        },
        view: {
            menu: {
                export: "Экспорт списка поставок",
            },
            print: {
                title: 'Печать поставщика',
                purchases: 'Печатать поставки',
            },
            noPurchasesYet: 'Поставок пока нет',
            exportMessage: "Экспорт поставок от поставщика"
        },
        edit: {
            addTitle: 'Новый поставщик',
            editTitle: 'Редактировать поставщика',
            fillByInn: 'Заполнить по ИНН',
            fillInnFirst: 'Сначала введите ИНН',
        },
        snackbar: {
            added: 'Поставщик добавлен',
            edited: 'Поставщик обновлен',
            deleted: 'Поставщик удален',
            merged: "Поставщики объединены",
            emailCopied: "Email скопирован",
            phoneCopied: "Телефон скопирован"
        }
    },
    en: {
        type: 'Type',
        personalName: 'Name',
        legalName: 'Name',
        inn: 'INN',
        kpp: 'KPP',
        ogrn: 'OGRN',
        address: 'Address',
        manager: 'Manager',
        post: 'Position',
        phone: 'Phone',
        email: 'Email',
        agreement: 'Agreement',
        purchasesCount: 'Purchases count',
        purchasesTotal: 'Purchases total price',
        deliveryDaysAvg: "Average delivery time",
        deliveryDays: "Delivery days",
        purchaseTotal: "Total price",
        exportMessage: "Export suppliers list",
        mergeMessage: 'No information will be lost. Are you sure?',
        noDataCta: {
            title: 'Your suppliers will be shown here',
            subtitle: 'You can add a supplier during the purchase creation process or right here'
        },
        view: {
            menu: {
                export: "Export purchases list",
            },
            print: {
                title: 'Print supplier',
                purchases: 'Print purchases',
            },
            noPurchasesYet: 'No purchases yet',
            exportMessage: "Export purchases from supplier"
        },
        edit: {
            addTitle: 'New supplier',
            editTitle: 'Edit supplier',
            fillByInn: 'Fill by INN',
            fillInnFirst: 'Enter INN first',
        },
        snackbar: {
            added: 'Supplier added',
            edited: 'Supplier updated',
            deleted: 'Supplier deleted',
            merged: "Suppliers merged",
            emailCopied: "Email copied",
            phoneCopied: "Phone copied"
        }
    }
}