// translations/register.js

export default {
    ru: {
        title: "Регистрация в Controlata",
        subtitle: "Полная версия без ограничений абсолютно бесплатно на 14 дней",
        form: {
            name: "Ваше имя",
            company: "Компания",
            email: "Электронная почта",
            password: "Пароль",
            checkboxError: "Пожалуйста, согласитесь с юридической информацией",
            agreeWith: "Согласен с",
            button: "Регистрация"
        },
        haveAccount: "Уже есть аккаунт?",
        login: "Войти",
        backToSite: "вернуться на сайт",
        userExists: {
            title: "Этот email уже используется",
            text: "Похоже, вы у нас уже регистрировались. Попробуйте войти в систему с вашим email-ом и паролем. Если вы забыли пароль, его можно восстановить на странице входа."
        }
    },
    en: {
        title: "Sign Up for Controlata",
        subtitle: "Full version with unlimited access, free for 14 days",
        form: {
            name: "Your name",
            company: "Company",
            email: "Email address",
            password: "Password",
            checkboxError: "Please agree to the legal information",
            agreeWith: "I agree to",
            button: "Create account"
        },
        haveAccount: "Already have an account?",
        login: "Sign in",
        backToSite: "return to website",
        userExists: {
            title: "This email is already in use",
            text: "It looks like you've already registered with us. Try signing in with your email and password. If you forgot your password, you can reset it on the sign-in page."
        }
    }
}