// translations/materials.js

export default {
    ru: {
        price: 'Цена',
        materialsLimitReached: 'Достигнут лимит материалов',
        noDataCta: {
            title: 'Начните с добавления своего первого материала',
            subtitle: 'После добавления материалов, вы сможете добавить продукты, которые делаете из них',
        },
        create: {
            title: 'Создать',
            purchase: 'Создать поставку',
            transfer: 'Создать перемещение',
            audit: 'Создать инвентаризацию',
        },
        delete: {
            message: "Материал будут удален в том числе из состава продуктов, в которые он входит. Восстановить материал будет невозможно. Вы уверены?",
            multipleMessage: "Материалы будут удалены в том числе из состава продуктов, в которые они входят. Восстановить их будет невозможно. Вы уверены, что хотите удалить эти материалы?",
            multipleSnackbar: 'Материалы удалены',
        },
        hide: {
            disabledTooltip: "Нельзя скрыть материалы, у которых есть остаток на складе",
            message1: 'Материалы будут скрыты со склада',
            message2: "Вы всегда сможете настроить, какие материалы должны отображаться на этом складе в настройках склада. Скрыть эти материалы?",
            multipleSnackbar: 'Материалы скрыты',
        },
        exportMessage: "Экспорт списка материалов",
        view: {
            menu: {
                export: 'Экспорт истории'
            },
            usedIn: 'Используется в составе',
            usedIn1Product: 'продукта',
            usedInNProducts: 'продуктов',
            correctionEdit: "Редактирование корректировки остатка",
            creationEdit: "Редактирование создания материала",
            initialStock: 'Начальный остаток',
            print: {
                title: 'Печать материала',
                products: 'Печатать продукты',
                history: 'Печатать историю',
                hidePrices: 'Скрыть стоимость',
            },
            amountUsed: 'Количество в составе',
            exportMessage: 'Экспорт истории материала'
        },
        edit: {
            unitChangeWarning: 'Другие единицы измерения<br />недоступны, так как материал<br />используется в составе некоторых<br />продуктов',
            multiple: {
                differentValues: 'Разные значения',
                differentUnits: 'Разные единицы измерения',
            }
        },
        stockUpdate: {
            title: 'Обновить остаток',
            type: 'Тип',
        },
        snackbar: {
            added: "Материал добавлен",
            edited: "Материал обновлен",
            deleted: "Материал удален",
            hidden: "Материал скрыт",
            editedMultiple: "Материалы обновлены",
            deletedMultiple: "Материалы удалены",
            hiddenMultiple: "Материалы скрыты",
        }
    },
    en: {
        price: 'Price',
        materialsLimitReached: 'Materials limit reached',
        noDataCta: {
            title: 'Start by adding your first material',
            subtitle: 'After adding materials, you can add products that you make from them',
        },
        create: {
            title: 'Create',
            purchase: 'Create purchase',
            transfer: 'Create transfer',
            audit: 'Create audit',
        },
        delete: {
            message: "The material will be removed from all products where it is used. This action cannot be undone. Are you sure?",
            multipleMessage: "The materials will be removed from all products where they are used. This action cannot be undone. Are you sure you want to delete these materials?",
            multipleSnackbar: 'Materials deleted',
        },
        hide: {
            disabledTooltip: "Cannot hide materials with remaining stock",
            message1: 'Materials will be hidden from this storage location',
            message2: "You can always configure which materials should be displayed in this storage location in the settings. Hide these materials?",
            multipleSnackbar: 'Materials hidden',
        },
        exportMessage: "Export materials list",
        view: {
            menu: {
                export: 'Export history'
            },
            usedIn: 'Used in',
            usedIn1Product: 'product',
            usedInNProducts: 'products',
            correctionEdit: "Edit stock correction",
            creationEdit: "Edit material creation",
            initialStock: 'Initial stock',
            print: {
                title: 'Print material',
                products: 'Print products',
                history: 'Print history',
                hidePrices: 'Hide prices',
            },
            amountUsed: 'Amount used',
            exportMessage: 'Export material\'s history'
        },
        edit: {
            unitChangeWarning: 'Other units are not available because the material is used in some products',
            multiple: {
                differentValues: 'Different values',
                differentUnits: 'Different units of measure',
            }
        },
        stockUpdate: {
            title: 'Update stock',
            type: 'Type',
        },
        snackbar: {
            added: "Material added",
            edited: "Material updated",
            deleted: "Material deleted",
            hidden: "Material hidden",
            editedMultiple: "Materials updated",
            deletedMultiple: "Materials deleted",
            hiddenMultiple: "Materials hidden",
        }
    }
}