export default {
    ru: {
        add: 'Добавить файл',
        sizeErrorTitle: 'Файлы слишком большие',
        sizeErrorLimitMessage: 'Общий размер файлов при загрузке не должен превышать ',
        sizeErrorActualSizeMessage: 'Размер загружаемых файлов - '
    },
    en: {
        add: 'Upload a file',
        sizeErrorTitle: 'Files are too large',
        sizeErrorLimitMessage: 'The total size of uploaded files should not exceed ',
        sizeErrorActualSizeMessage: 'The size of uploaded files is '
    }
}