export default {
    ru: {
        price: "Цена",
        pricePerUnit: "Цена за ед.",
        cost: "Стоимость",
        components: "Состав",
        noDataCta: {
            title: "Добавьте свою первую поставку материалов",
            subtitle: "Так мы будем отслеживать остатки материалов и себестоимость ваших продуктов",
        },
        delete: {
            title: "Удаление поставки",
            message: "Удалить поставку ",
        },
        exportMessage: "Экспорт списка поставок",
        view: {
            menu: {
                torg12: "Скачать накладную",
                export: "Экспорт состава",
            },
            print: {
                title: 'Печать поставки',
                hidePrices: 'Скрыть стоимость',
            },
            exportMessage: 'Экспорт состава поставки'
        },
        edit: {
            addTitle: 'Новая поставка',
            editTitle: 'Редактировать поставку',
            addRow: 'Добавить строку',
            addMaterial: 'Создать новый материал',
            duplicateWarning: 'Это повтор. Вы можете создать поставку с повторяющимися материалами, мы просто предупреждаем.',
            createFirst: 'Сначала создайте хотя бы один материал',
        },
        snackbar: {
            added: "Поставка добавлена",
            edited: "Поставка обновлена",
            deleted: "Поставка удалена",
        }
    },
    en: {
        price: "Price",
        pricePerUnit: "Price per unit",
        cost: "Total price",
        components: "Items",
        noDataCta: {
            title: "Add your first materials purchase",
            subtitle: "This way we will track material inventory and cost of your products",
        },
        delete: {
            title: "Delete purchase",
            message: "Delete purchase ",
        },
        exportMessage: "Export purchases list",
        view: {
            menu: {
                torg12: "",
                export: "Export items",
            },
            print: {
                title: 'Print purchase',
                hidePrices: 'Hide prices',
            },
            exportMessage: 'Export purchased items'
        },
        edit: {
            addTitle: 'New purchase',
            editTitle: 'Edit purchase',
            addRow: 'Add row',
            addMaterial: 'Create new material',
            duplicateWarning: 'This is a duplicate. You can create a purchase with duplicate materials, we are just warning you.',
            createFirst: 'Create at least one material first',
        },
        snackbar: {
            added: "Purchase added",
            edited: "Purchase updated",
            deleted: "Purchase deleted",
        }
    }
}