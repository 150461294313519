import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/lib/router.js'
import vuetify from '@/plugins/vuetify'
import api from '@/lib/api'
import i18n from '@/plugins/i18n.js'
import formatter from '@/plugins/formatter.js'
import { init } from '@amplitude/analytics-browser'
import VueApexCharts from "vue-apexcharts"
import * as Sentry from "@sentry/vue"

Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)

Apex.chart = {
	locales: [{
		"name": "ru",
		"options": {
			"months": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			"shortMonths": ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
			"days": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
			"shortDays": ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
			"toolbar": {
				"exportToSVG": "Скачать SVG",
				"exportToPNG": "Скачать PNG",
				"menu": "Меню",
				"selection": "Выбор",
				"selectionZoom": "Увеличить выбранное",
				"zoomIn": "Увеличить",
				"zoomOut": "Уменьшить",
				"pan": "Panning",
				"reset": "Reset Zoom"
			}
		}
	}],
	defaultLocale: "ru"
}

Vue.use(i18n)

Vue.use(formatter)

// console.clear()

// Amplitude
init('f62fa84d6158eba06d3193f3c9481f21', null, {
	// optional configuration options
	saveEvents: true,
	includeUtm: true,
	includeReferrer: true
})

// Sentry
Sentry.init({
	Vue,
	dsn: "https://fb402e417e5407b7b14f98bed9edb65e@o4508681250734080.ingest.de.sentry.io/4508699215396944",
	integrations: [
		Sentry.replayIntegration(),
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Turn off production tip
Vue.config.productionTip = false

// Function to initialize Vue instance
function initializeVue() {
	const vm = new Vue({
		router,
		vuetify,
		store,
		render: h => h(App),
	}).$mount('#app')

	window.vm = vm
	return vm
}

// Read auth token from local storage
if (localStorage.getItem('token')) {
	api.post('auth/authorize').then(response => {
		initializeVue()
		store.dispatch("login", response.data)
	})
}
else {
	initializeVue()
}