export default {
    ru: {
        price: "Стоимость",
        cost: "Себестоимость",
        cst: "Себ-ть",
        forOrder: "Под заказ",
        linkedWriteoffs: "Связанные списания",
        producedProducts: "Произведенные продукты",
        usedMaterials: "Использованные материалы",
        byProducts: "по продуктам",
        summary: "суммарно",
        per: 'на', // себестоимость на 1 шт, глобальный t('per') == 'за'
        forecast: {
            button: "Проверить план",
            title: "План производства",
            messageMaterialsOnly: "Прогноз остатков материалов при выполнении плана производства",
            messageMaterialsAndProducts: "Прогноз остатков материалов и продуктов при выполнении плана производства",
            onlyBelowMinimum: "Только с прогнозом ниже минимума",
            noProduction: "Нет запланированного производства по выбранным складам",
            allIsOk: "Все в порядке",
            on: 'На дату',
            headers: {
                forecast: "Прогноз",
                minimum: "Минимум",
            }
        },
        noDataCta: {
            title: "Создайте свое первое производство",
            subtitle: "Вы можете запланировать производство и посмотреть, хватает ли вам материалов",
        },
        delete: {
            title: "Удаление производства",
            message: "Удалить производство ",
        },
        exportMessage: "Экспорт списка производств",
        view: {
            menu: {
                exportProducts: "Экспорт продуктов",
                exportMaterials: "Экспорт материалов",
            },
            print: {
                title: 'Печать производства',
                products: 'Печатать произведенные продукты',
                components: 'Печатать использованные материалы',
                resources: 'Печатать использованные ресурсы',
                perProduct: 'Детализировать по продуктам',
                hidePrices: 'Скрыть стоимость',
            },
            materialsCost: 'Себестоимость материалов',
            resourcesCost: 'Себестоимость ресурсов',
            noProducts: 'Продукты не указаны',
            noMaterials: 'Материалы не указаны',
            noResources: 'Ресурсы не указаны',
            costOfOne: 'Себестоимость 1',
            deleteTooltip: 'Это производство привязано к заказу. Для его удаления перейдите в привязанный заказ и поменяйте тип отгрузки на отгрузку со склада.',
            exportMaterialsMessage: 'Экспорт списка использованных материалов',
            exportProductsMessage: 'Экспорт списка произведенных продуктов',
        },
        edit: {
            addTitle: 'Новое производство',
            editTitle: 'Редактировать производство',
            addRow: 'Добавить строку',
            duplicateWarning: '',
            createFirst: '',
        },
        snackbar: {
            added: "Производство добавлена",
            edited: "Производство обновлена",
            deleted: "Производство удалена",
        }
    },
    en: {
        price: "Sales price",
        cost: "Cost",
        cst: "Cost",
        forOrder: "Order",
        linkedWriteoffs: "Linked write-offs",
        producedProducts: "Manufactured products",
        usedMaterials: "Used materials",
        byProducts: "by product",
        summary: "summarized",
        per: 'per',
        forecast: {
            button: "Check forecast",
            title: "Production forecast",
            messageMaterialsOnly: "Materials inventory level forecast based on production plan",
            messageMaterialsAndProducts: "Materials and products inventory level forecast based on production plan",
            onlyBelowMinimum: "Only with forecast below minimum",
            noProduction: "No planned production for selected storage locations",
            allIsOk: "All is fine",
            on: 'On',
            headers: {
                forecast: "Forecast",
                minimum: "Minimum",
            }
        },
        noDataCta: {
            title: "Create your first production",
            subtitle: "You can plan production and check if you have enough materials",
        },
        delete: {
            title: "Delete production",
            message: "Delete production ",
        },
        exportMessage: "Export production list",
        view: {
            menu: {
                exportProducts: "Export products",
                exportMaterials: "Export materials",
            },
            print: {
                title: 'Print production',
                products: 'Print manufactured products',
                components: 'Print used materials',
                resources: 'Print used resources',
                perProduct: 'By product',
                hidePrices: 'Hide prices',
            },
            materialsCost: 'Materials cost',
            resourcesCost: 'Resources cost',
            noProducts: 'No products',
            noMaterials: 'No materials',
            noResources: 'No resources',
            costOfOne: 'Cost of 1',
            deleteTooltip: 'This production is linked to an order. To delete it, go to the linked order and unlink it.', // TODO: переписать, учитывая перевод блока заказов
            exportMaterialsMessage: 'Export used materials list',
            exportProductsMessage: 'Export manufactured products list',
        },
        edit: {
            addTitle: 'New production',
            editTitle: 'Edit production',
            addRow: 'Add row',
            addMaterial: '',
            duplicateWarning: '',
            createFirst: '',
        },
        snackbar: {
            added: "Production added",
            edited: "Production updated",
            deleted: "Production deleted",
        }
    }
}