// translations/toolbars.js

export default {
    ru: {
        trialIsEnding: {
            please: 'Пожалуйста, ',
            choosePlan: 'выберите тарифный план',
            toUseService: ', чтобы и дальше пользоваться сервисом',
            endsIn: 'Пробный период закончится через ',
            ended: 'Пробный период закончился'
        },
        invoiceIsDue: {
            please: 'Пожалуйста, ',
            payInvoice: 'оплатите счет',
            toUseService: ', чтобы и дальше пользоваться сервисом'
        },
        reload: {
            weUpdated: 'Мы обновили сервис.',
            please: 'Пожалуйста, ',
            reload: 'перезагрузите страницу',
            toUseService: ', чтобы продолжить работу'
        }
    },
    en: {
        trialIsEnding: {
            please: 'Please ',
            choosePlan: 'choose a plan',
            toUseService: ' to continue using the service',
            endsIn: 'Trial period ends in ',
            ended: 'Trial period has ended'
        },
        invoiceIsDue: {
            please: 'Please ',
            payInvoice: 'pay the invoice',
            toUseService: ' to continue using the service'
        },
        reload: {
            weUpdated: 'We have updated the service.',
            please: 'Please ',
            reload: 'reload the page',
            toUseService: ' to continue working'
        }
    }
}